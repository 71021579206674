import './Blueberry.css';
import NouenView from '../img/NouenView.JPG';
import JinsekiView from '../img/JinsekiView.JPG';
import BlueBerryDAMA from '../img/BlueBerryDAMA.JPG';
import BlueBerryPic from '../img/BlueBerryPic.JPG';
function Blueberry() {
  return (
    <div className="Blueberry">
      <h1>伊草農園のブルーベリー</h1>
      <img src={BlueBerryDAMA} alt="ブルーベリー玉" />
      <h2>農園の紹介</h2>
      <img src={NouenView} alt="伊草農園 外観" />
      <p>伊草農園のブルーベリー畑は、神石高原町の豊松地区にあります。</p>
      <p>源流の里で野生生物がたくさん生息している自然あふれる里です。</p>
      <p>春になり、スズランのような白い小さなブルーベリーの花が咲くと、ミツバチやマルハナバチがどこからともなく飛んできて、一生懸命受粉してくれます。</p>
      <p>朝露で蜘蛛の巣がキラキラ輝いて、ブルーベリー畑が蜘蛛のキャンパスになります。</p>
      <p>アマガエルは昆虫をせっせとハントしてくれます。</p>
      <p>ウグイスの歌声は、美しい自然の音楽のようです。</p>
      <p>収穫の時期になると小鳥が実を食べにくるのを、カラスが守ってくれます。</p>
      <p>忙しい時期は、サルが収穫を手伝ってくれます。</p>
      <p>そしてイノシシは、ブルーベリー畑を耕してくれます。</p>
      <p>そのような自然あふれる環境を壊さないように、農薬や除草剤、化学肥料を使用しない農業をしています。</p>
      <h2>ブルーベリーの品種について</h2>
      <table align="left" border="1" cellPadding="1" cellSpacing="1" style={{ width: "auto" }}>
        <thead>
          <tr>
            <th scope="col" style={{ width: "150px" }}>&nbsp;</th>
            <th scope="col">ハイブッシュ系</th>
            <th scope="col">ラビットアイ系</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">栽培しやすい地域</th>
            <td>寒冷地・高冷地</td>
            <td>温暖地</td>
          </tr>
          <tr>
            <th scope="row">収穫時期</th>
            <td>6月上旬～7月下旬</td>
            <td>7月上旬～9月上旬</td>
          </tr>
          <tr>
            <th scope="row">味の特徴</th>
            <td>良質。香りと風味、酸味と甘味が調和している。大玉になる品種が多い。</td>
            <td>味はハイブッシュに劣り、甘味はあるが酸味は少ない。皮や種が目立つ。</td>
          </tr>
          <tr>
            <th rowSpan="6" scope="row">栽培特性</th>
            <td>比較的収穫量が少ない。</td>
            <td>収穫量が多い。</td>
          </tr>
          <tr>
            <td>自家受粉する。</td>
            <td>自家受粉出来ない。</td>
          </tr>
          <tr>
            <td>寒さに強い。</td>
            <td>暑さに強い。</td>
          </tr>
          <tr>
            <td>根があまり伸びない。</td>
            <td>全体的に育つ勢いが強い。</td>
          </tr>
          <tr>
            <td>酸性土壌を好み土質も選ぶため、日本で育てるには土壌改良がほぼ必須。</td>
            <td>土質をあまり選ばない。</td>
          </tr>
          <tr>
            <td>栽培が難しい。</td>
            <td>栽培が簡単にできる。</td>
          </tr>
        </tbody>
      </table>
      <h2>伊草農園の地域</h2>
      <img src={JinsekiView} alt="神石高原町 展望" />
      <p>神石高原町は広島県中東部に位置し、岡山県に接する町です。</p>
      <p>その中でも豊松は標高500m以上の高原地形に位置していて、昼夜の温度さが大きいです。</p>
      <p>そのため、北部ハイブッシュブルーベリーを栽培するのに適した地域となっております。</p>
      <h2>北部ハイブッシュブルーベリーの特徴</h2>
      <img src={BlueBerryPic} alt="ブルーベリー木" />
      <p>寒さに強く、寒冷地及び高冷地での栽培に適した系統です。北部ハイブッシュ系は、香りと風味、そして酸味と甘みが調和しています。</p>
      <p>その中から特に優れた品種を選んで栽培しています。</p>
      <h2>伊草農園が栽培している北部ハイブッシュの品種</h2>
      <h3>デューク種</h3>
      <p>やや甘みが強く、フレッシュな味。</p>
      <h3>ノースランド種</h3>
      <p>小中粒だが、香りがよく甘みが強い。</p>
      <h3>チャンドラー種</h3>
      <p>果実が特に大きい。500円玉と同等のサイズになるものもある。</p>
      <p>甘味と酸味のバランスが良く、風味もある。</p>
      <h3>レガシー種</h3>
      <p>風味が特に強い。</p>
      <p>抗酸化物質が多く含まれている。抗酸化物質のポリフェノールを約47％含有している。</p>
      <p>甘味と少々の酸味がある。</p>
      <h2>ブルーベリージャムのこだわり</h2>
      <p>伊草農園では、自社で栽培したブルーベリーを、自社でジャムに加工しています。</p>
      <p>ジャム専用に栽培し、ジャムに最適な時期に手摘みし、良い果実だけを新鮮なまま即冷凍保存しています。</p>
      <p>レモン果汁やペクチンなどの食品添加物を一切使用せず、加水せず、不添加で味の異なるこだわりの国産砂糖を使用し、ブルーベリージャムを作っています。</p>
    </div>
  );
}
export default Blueberry;
