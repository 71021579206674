import React from 'react';
import './Footer.css';
function Footer() {
  return (
    <div className="Footer">

    </div>
  );
}
export default Footer;
