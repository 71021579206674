import './Top.css';
import Producer from '../img/Producer.JPG';
function Top() {
  return (
    <div className="Top">
      <h1>自然栽培ブルーベリージャム専門農家</h1>
      <h2>伊草農園では、農薬・化学肥料・食品添加物も使用せずに、ジャムを作っています。</h2>
      <p><img src={Producer} alt="topページヘのリンク"/></p>
    </div>
  );
}
export default Top;
