import React, { useEffect, useState, } from "react";
import { useLocation } from 'react-router-dom';
import './Headder.css';
import HamburgerIcon from '../img/HamburgerIcon.png';
import CloseIcon from '../img/CloseIcon.png';


const CONTENT_WIDTH = 150;
function Headder({ topPage, pages }) {
  const [isMobile, setIsMobile] = useState(false);
  const [isHamburgerShow, setIsHamburgerShow] = useState(false);


  const location = useLocation();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < CONTENT_WIDTH * (pages.length + 2));
      setIsHamburgerShow(false);
    };

    handleResize(); // 初回レンダリング時に実行
    window.addEventListener("resize", handleResize); // リサイズ時に実行

    return () => {
      window.removeEventListener("resize", handleResize); // クリーンアップ
    };
  }, [pages.length]);

  const linkHtml = () => {
    if (isMobile) {
      return (
        <div className={isHamburgerShow ? "Headder-Auto" : "Headder"}>
          <a className="Headder-Logo" href={topPage.url}><img src={topPage.logo} alt="topページヘのリンク" /></a>
          <ul className="Headder-Menu">
            {/* ハンバーガーメニューのトリガーボタン */}
            <button onClick={() => setIsHamburgerShow(!isHamburgerShow)} className="Hamburger-Menu-Button">
              <img src={isHamburgerShow ? CloseIcon : HamburgerIcon} alt="menu" />
            </button>
          </ul>
          {/* ハンバーガーメニューのリスト */}
          {isHamburgerShow &&
            <ul className="Hamburger-Menu-List">
              {pages.map((page, index) => (
                <li key={index}>
                  <a
                    href={page.url}
                    rel="noreferrer"
                    className={
                      location.pathname === page.url ? "Hamburger-Menu-Locate-Link" : "Hamburger-Menu-Link"
                    }
                  >
                    {page.title}
                  </a>
                </li>
              ))}
              <li key={1000} className="Hamburger-Shop-Button" >
                <a href="https://www.creema.jp/c/aoitorinooka/item/onsale" target="_blank" rel="noreferrer" className="Hamburger-Shop-Button-Link">
                  商品ページへ(creema)
                </a>
              </li>
            </ul>
          }
        </div>);
    } else {
      return (
        <div className="Headder">
          <a className="Headder-Logo" href={topPage.url}><img src={topPage.logo} alt="topページヘのリンク" /></a>
          <ul className="Headder-List">
            {pages.map((page, index) => (
              <li key={index}>
                <a href={page.url} rel="noreferrer" className={location.pathname === page.url ? "Locate-Link" : "Page-Link"}>{page.title}</a>
              </li>
            ))}
            <li key={1000} className="Shop-Button" >
              <a href="https://www.creema.jp/c/aoitorinooka/item/onsale" target="_blank" rel="noreferrer" className="Shop-Button-Link">
                商品ページへ(creema)
              </a>
            </li>
          </ul>
        </div>
      );
    }
  }

  return linkHtml();
}
export default Headder;