import './App.css';
import Logo from './Icon.PNG';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Headder from './parts/Headder';
import Footer from './parts/Footer';
import Top from './pages/Top';
import Blueberry from './pages/Blueberry';
import Jams from './pages/Jams';

function App() {
  const topPage = { logo: Logo, url: "/", element: <Top /> };
  const pages =
    [
      { title: "ブルーベリー", url: "/blueberry", element: <Blueberry /> },
      { title: "商品紹介", url: "/jams", element: <Jams /> }
    ];
  const routesHtml = pages.map((page, index) => (
    <Route key={index} path={page.url} element={<div className="Page">{page.element}</div>} />
  ));
  return (
    <Router>
      <Headder topPage={topPage} pages={pages} />
      <Routes>
        <Route key={-1} path={topPage.url} element={<div className="Page">{topPage.element}</div>} />
        {routesHtml}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
