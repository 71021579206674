import './Jams.css';
import imgJams1 from '../img/Jams1.JPG';
import imgJams2 from '../img/Jams2.JPG';

function Jams() {
  return (
    <div className="Jams">
      <h1>究極のブルーベリージャム</h1>
      <h2>伊草農園のブルーベリージャムラインナップ</h2>
      <table>
        <tbody>
          <tr>
            <td><img src={imgJams2} alt="ジャム1" /></td>
            <td><img src={imgJams1} alt="ジャム2" /></td>
          </tr>
        </tbody>
      </table>
      <hr />
      <h2>オンライン直売所「creema」さんで、販売しています！</h2>
      <a href="https://www.creema.jp/c/aoitorinooka/item/onsale" className="button" rel="noreferrer" target="_blank">
        商品を見る
      </a>
      <hr />
      <h2>楽天ふるさと納税にも登録しています。</h2>
      <a href="https://search.rakuten.co.jp/search/mall/%E4%BC%8A%E8%8D%89%E8%BE%B2%E5%9C%92/" className="button" rel="noreferrer" target="_blank">
        楽天ふるさと納税
      </a>
    </div>
  );
}
export default Jams;
